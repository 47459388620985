<template>
  <div>
    <h2 class="sub-title">
      Create New Feed Mill
    </h2>

    <div class="bg-grey-lightest rounded-medium p-9">
      <div class="mx-auto max-w-lg">
        <feed-edit
          v-model="localDiet"
          label="name"
          track-by="id"
          save-button-title="Create Feed Mill"
          :delete-button="false"
          :options="ingredients"
          @save="onCreate"
        >
          <button
            class="btn-danger"
            @click="$router.push({name: 'feed-view'})"
          >
            Cancel
          </button>
        </feed-edit>
      </div>
    </div>
  </div>
</template>

<script>
import FeedEdit from '@/components/FeedEdit';
import {getIngredientsWithMandaroty} from '@/helpers/ingredientsWithMandatory';
import {mapGetters, mapMutations} from 'vuex';
import {v4 as uuidv4} from 'uuid';

export default {
  name: 'FeedCreate',

  components: {
    FeedEdit,
  },

  data: () => ({
    localDiet: {
      name: '',
      id: `${'temp-' + new Date().valueOf()}`,
      animal_id: null,
      created_at: new Date().toISOString(),
      allIngredients: [],
      ingredientDigestibility: [],
      diet_phases: [
        {
          'id': `${'temp-dietPhase-' + new Date().valueOf()}`,
          'name': 'Phase 1',
          'safetyMargin': 0,
          'feedIntake': 1,
          'dose': 50,
          'recommendedEnergy': 25,
          'order': 0,
          'ingredients': [],
          'nutritional_values': [],
          'phaseResult': []
        }
      ]
    }
  }),

  mounted() {
    this.$store.commit('setNavigation')
  },

  methods: {
    ...mapMutations({
      setDiets: 'feed/setDiets',
      setIngredientToActiveDietAllPhases: 'feed/setIngredientToActiveDietAllPhases'
    }),

    onCreate(diet) {
      for (const dietElement of diet.allIngredients) {
        for (const phase of diet.diet_phases) {
          phase.ingredients.push(dietElement)
        }
      }
      let dose = 50

      if (this.getSelectedProduct === 'proact') {
        dose = 200
        if (this.isActiveEggPoultry) {
          dose = 150
        }
      } else {
        dose = 50
        if (this.isActiveEggPoultry) {
          dose = 30
        }
      }
      for (const phase of diet.diet_phases) {
        phase.dose = dose

        phase.ingredients.push(...getIngredientsWithMandaroty(this.ingredients))
      }

      diet.ingredientDigestibility = this.$store.state.feed.ingredients.filter(el => {
        return el.digestibility
      }).sort((a, b) => {
        if (a.order < b.order) return -1
      })

      diet.diet_phases[0].nutritional_values = this.$store.state.phase.nutrients.map(nutrient => {
        if (nutrient.nutrientName === 'CP' || nutrient.nutrientName === 'd. Lys') {
          return {
            ...nutrient,
            displayName: this.getActiveAnimalType.type === 'swine' && nutrient.display_name_swine ? nutrient.display_name_swine : nutrient.nutrientName,
          }
        } else {
          return {
            ...nutrient,
            displayName: this.getActiveAnimalType.type === 'swine' ? nutrient.display_name_swine : nutrient.nutrientName,
            ratio: nutrient[this.getActiveRatioByAnimalType]
          }
        }
      })

      diet.diet_phases[0].nutritional_values.forEach(el => {
        el.id = `temp-${uuidv4()}`
      })

      this.setDiets({
        ...diet,
        'animal_id': this.getActiveAnimalType.id,
      })

      this.$router.push({name: 'feed-view'})

      this.$toast.open({
        type: 'info',
        message: `Diet <strong>${diet.name}</strong> was created!`
      })
    }
  },

  computed: {
    ...mapGetters({
      getActiveAnimalType: 'animalType/getActiveAnimalType',
      getActiveRatioByAnimalType: 'animalType/getActiveRatioByAnimalType',
      ingredients: 'feed/getIngredients',
      getActiveDiet: 'feed/getActiveDiet',
      isActiveMeatPoultry: 'animalType/isActiveMeatPoultry',
      isActiveEggPoultry: 'animalType/isActiveEggPoultry',
      getSelectedProduct: 'productSelect/getSelectedProduct',
    }),
  },
};
</script>

<style scoped>

</style>
